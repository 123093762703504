import { Team, Contract, TeamPlayerInfo, DraftPick, TradeProposalPlayer, TradeProposalPick, ContractPaymentSeason, LeagueSeason, ContractSeason } from "../../sdk/model";
import { MINIMUM_ONE_YEAR_SALARY } from './ContractUtils';


const formatter = new Intl.NumberFormat('en-US', {
   minimumFractionDigits: 0,      
   maximumFractionDigits: 2,
});


export const toSalary = function(number) {
  const absNumber = Math.abs(number);
  const formattedNumber = getAbsSalaryValue(absNumber);
  if (number < 0) {
    return '-' + formattedNumber;
  }
  return formattedNumber;
}

function getAbsSalaryValue(number) {
  number = number.toFixed(0);
  if (number < 1000) {
    return "$" + number;
  } else if (number < 1000000) {
    const thousands = number / 1000;
    return "$" + thousands.toFixed(0) + "K";
  }
  const millions = number / 1000000;
  return "$" + formatter.format(millions) + "M";
}

export const getCurrentSalary = function(player: TeamPlayerInfo, currentSeasonYear: number) {
  if(player.contract === undefined) {
    console.warn("Player does not have a contract info loaded", player);
    return 'NoContractInfo';
  }
  return getCurrentSalaryFromContract(player.contract, currentSeasonYear);
}

export const getCurrentSalaryAsNumber = function(player: TeamPlayerInfo, currentSeasonYear: number) {
  if(player.contract === undefined) {
    console.warn("Player does not have a contract info loaded", player);
    return 'NoContractInfo';
  }
  return getCurrentSalaryAsNumberFromContract(player.contract, currentSeasonYear);
}

export const getCurrentSalaryFromContract = function(contract: Contract, currentSeasonYear: number) {
  console.log("Current Season Year", currentSeasonYear)
  const currentContract = contract!.seasons!.find(season => 
    season.season_year == currentSeasonYear);
  return currentContract ? toSalary(currentContract.annual_salary) : 'NoContractInfo';
}

export const getCurrentSalaryAsNumberFromContract = function(contract: Contract, currentSeasonYear: number) {
  console.log("Current Season Year", currentSeasonYear)
  const currentContract = contract!.seasons!.find(season => 
    season.season_year == currentSeasonYear);
  return currentContract ? currentContract.annual_salary : 0;
}


export const getTeamsCommittedSalary = function(team: Team | undefined, currentSeasonYear: number | undefined) {
  return toSalary(team?.currentSeasonSalary);
}

export const getTeamsRemainingCapSpace = function(team: Team | undefined, currentSeasonYear: number | undefined, salaryCap: number | undefined) {
  if (!salaryCap) {
    return 0;
  }
  var committedSalary = team?.currentSeasonSalary ? team?.currentSeasonSalary : 0;
  var remainingCapSpace = salaryCap - committedSalary;
  return toSalary(remainingCapSpace);
}

export function getFinalSeasonOfContract(rosterPlayer) {
  if(rosterPlayer.contract === undefined) {
    return null;
  }
  return getFinalSeasonOfContractFromContract(rosterPlayer.contract);
}

export function getFinalSeasonOfContractFromContract(contract) {
  return Math.max.apply(null, contract.seasons.map(season => season.season_year));
}

export function getMinimumCostForFreeAgents(freeAgents: number, currentSeason : LeagueSeason) {
  const percentageOfSeasonRemaining = (currentSeason.finalWeek! - Math.max(currentSeason.currentWeek!, 1) + 1) / currentSeason.finalWeek!;
  return freeAgents * MINIMUM_ONE_YEAR_SALARY * percentageOfSeasonRemaining;
}

function getTradedContractValue(contractSeason: ContractSeason, seasonIndex, contractSeasons, player: TradeProposalPlayer, receivePlayer: boolean) {
  return contractSeason.annual_salary! - contractSeason.weeks!.flatMap(week => week.payments).filter(payment => payment && (payment.completed || (!payment.teamId && (!receivePlayer || !player.removeFromIr)))).reduce((partialSum, payment) => partialSum + payment?.price!, 0);
}

function getDroppedContractValue(contractSeason, seasonIndex, contractSeasons) {
  if (seasonIndex === 0) {
    return contractSeason.weeks!.reduce((partialSum, week) => partialSum + week.fullSalary! - week.guaranteedSalary! - week.payments!.filter(payment => payment.completed || !payment.teamId).reduce((partialPaymentSum, payment) => partialPaymentSum + payment.price!, 0), 0)
  } if (seasonIndex === 1) {
    const committedSalary = contractSeason.weeks!.flatMap(week => week.payments).filter(payment => !payment!.completed).reduce((partialSum, payment) => partialSum + payment?.price!, 0);
    return committedSalary - contractSeasons.filter(season => season.season_year >= contractSeason.season_year).reduce((accelerationSum, season) => {
      return accelerationSum + (season.weeks!.reduce((partialSum, week) => partialSum + week.guaranteedSalary! - week.payments!.filter(payment => payment.completed).reduce((partialPaymentSum, payment) => partialPaymentSum + payment.price!, 0), 0));
    }, 0);
  }
  return 0;
}

function getIrContractValue(contractSeason, seasonIndex, contractSeasons) {
  if (seasonIndex === 0) {
    return contractSeason.weeks!.reduce((partialSum, week) => partialSum + week.fullSalary! - week.payments!.reduce((partialPaymentSum, payment) => partialPaymentSum + (payment.completed ? payment.price! : payment.price! / 2), 0), 0)
  }
  return contractSeason.weeks!.reduce((partialSum, week) => partialSum + week.fullSalary! - (week.payments ? week.payments.filter(payment => payment.completed).reduce((partialPaymentSum, payment) => partialPaymentSum + payment.price!, 0) : 0), 0);
}

const ACTION_TO_CONTRACT_VALUE_FUNCTION = new Map([
  ["TRADE", getTradedContractValue],
  ["MOVE_TO_IR", getIrContractValue],
  ["RETURN_FROM_IR", getIrContractValue]
]);

export function getNewSalary(team: Team, teamPlayerMap: Map<string | undefined, TeamPlayerInfo | undefined>, pickMap: Map<string | undefined, DraftPick | undefined>, currentSalary: number, players: Array<TradeProposalPlayer>, picks: Array<TradeProposalPick>, seasonYear : number, seasonIndex : number, newEmptyRosterSpots : number | undefined, currentSeason : LeagueSeason | undefined) {
  const newPlayerSalary = players.filter(player => player.toTeamId === team.id || player.fromTeamId === team.id).map(player => {
    const teamPlayer = teamPlayerMap.get(player.playerId);
    const contractSeason = teamPlayer?.contract?.seasons?.find(season => season.season_year === seasonYear)
    const contractValueFunction = ACTION_TO_CONTRACT_VALUE_FUNCTION.get(player.action!) || getDroppedContractValue;
    const contractValue = contractSeason ? contractValueFunction.call(this, contractSeason, seasonIndex, teamPlayer?.contract?.seasons, player, player.toTeamId === team.id) : 0;
    return contractValue * (player.fromTeamId === team.id && player.action !== "RETURN_FROM_IR" ? -1 : 1);
  }).reduce((partialSum, value) => partialSum + value, 0);
  
  const newPickSalary = picks.filter(pick => pick.toTeamId === team.id || pick.fromTeamId === team.id).map(pickAsset => {
    const pick = pickMap.get(pickAsset.draftPickId);
    const contractSeason = pick?.contract?.seasons?.find(season => season.season_year === seasonYear)
    const contractValue = contractSeason && contractSeason.annual_salary ? contractSeason.annual_salary : 0;
    return contractValue * (pickAsset.fromTeamId === team.id ? -1 : 1);
    
  }).reduce((partialSum, value) => partialSum + value, 0);
  return currentSalary + newPlayerSalary + newPickSalary + (newEmptyRosterSpots ? getMinimumCostForFreeAgents(newEmptyRosterSpots, currentSeason!) : 0);
}

export function getSalaryForSeason(paymentSeasons: Array<ContractPaymentSeason>) {
  return paymentSeasons ? paymentSeasons.flatMap(paymentSeason => paymentSeason.payments).reduce((partialSum, payment) => partialSum + payment?.price!, 0) : 0;
}