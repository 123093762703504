import * as React from 'react';
import { Paper, Typography, Dialog, DialogContent, TableContainer, Table, TableHead, TableBody, TableRow, TableCell, Modal } from '@mui/material';
import Close from "@mui/icons-material/Close";
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import { teamApi } from '../../../adapters/APIExporter';
import { useNavigate, NavigateFunction } from 'react-router-dom';
import { League, Team, User, TeamSeason, LeagueSeason } from '../../../sdk/model';
import UserInfoCard from '../../cards/userCard'
import TeamDisplay from './TeamDisplay';

interface allTeamsForSeasonProps {
  league : League,
  leagueSeason : LeagueSeason,
  teamSeasons : TeamSeason[],
  joinEnabled : boolean,
  showWaiverPriority : boolean,
}

function AllTeamsForSeason(props : allTeamsForSeasonProps) {
  const [viewingImage, setViewingImage] = React.useState<string | undefined>(undefined);
  const [errorMessage, setErrorMessage] = React.useState("");
  const [viewingUser, setViewingUser] = React.useState<User | undefined>(undefined);
  const navigate = useNavigate();

  function joinTeam(team: Team) {
    // TODO reload page after join
  	teamApi.joinTeam(team.id)
  }
  
  function generateTable(teamCells) {
    return <TableContainer className="centered" component={Paper} sx={{width: '75%'}}><Table>
        <TableHead><TableRow>
          <TableCell>Team</TableCell>
          <TableCell>Record</TableCell>
          <TableCell>Points For</TableCell>
          <TableCell>Points Against</TableCell>
          <TableCell>Owner(s)</TableCell>
          {props.showWaiverPriority && <TableCell>Waiver Priority</TableCell>}
        </TableRow></TableHead>
        <TableBody>
          {teamCells}
        </TableBody>
      </Table></TableContainer>
  }
  
  function getDivisionContent() {
    if (props.leagueSeason.divisions?.length === 0) {
      return null;
    }
    const teamSeasonMap = new Map(props.teamSeasons.map(teamSeason => [teamSeason.teamId, teamSeason]));
    return <Box>
      <Typography variant="h5" component="div" color="text.secondary" sx={{ flexGrow: 1 }}>
        Division Standings
      </Typography>
      <Dialog open={viewingImage !== undefined} maxWidth="md" fullWidth>
        <DialogContent>
          <Box position="absolute" top={0} right={0}>
            <IconButton onClick={() => setViewingImage(undefined)}>
              <Close />
            </IconButton>
          </Box>
          <Box component="img" src={viewingImage} sx={{width: '100%', height:'100%'}} />
        </DialogContent>
      </Dialog>
      {props.leagueSeason.divisions?.map(division => {
        const teamCells = division.teams?.map(teamId => teamSeasonMap.get(teamId)!)
          .sort((teamSeason1 : TeamSeason, teamSeason2 : TeamSeason) => teamSeason1.divisionRank! - teamSeason2.divisionRank!)
          .map((teamSeason : TeamSeason, index) => { 
            let waiverPriority = props.showWaiverPriority ? [...Array(props.league.waiverPriorityOrder!.length).keys()].find(waiverIndex => props.league.waiverPriorityOrder![waiverIndex] === teamSeason.teamId) : null;
            waiverPriority = waiverPriority === undefined ? null : waiverPriority + 1 ;
            return teamCell(props.league.id!, teamSeason, index, props.joinEnabled, joinTeam, setViewingUser, navigate, waiverPriority, props.showWaiverPriority);
          });
        const tableContents = generateTable(teamCells);
        return <Box key={division.name}>
          <Typography variant="h6" component="div" color="text.secondary" sx={{ flexGrow: 1 }}>
            <IconButton sx={{ p: 0 }} disabled={!division.picture} onClick={() => setViewingImage(division.picture)}>
              <Avatar alt={division.name} src={division.picture}>{!division.picture && division.name?.substring(0,1)}</Avatar>
            </IconButton>
            {division.name}
          </Typography>
          {tableContents}
        </Box>
      })}
      <br/>
    </Box>
  }
  
  function getOverallContent() {
    const teamCells = [...props.teamSeasons].sort((teamSeason1 : TeamSeason, teamSeason2 : TeamSeason) => teamSeason1.overallRank! - teamSeason2.overallRank!).map((teamSeason : TeamSeason, index) => { 
       let waiverPriority = props.showWaiverPriority ? [...Array(props.league.waiverPriorityOrder!.length).keys()].find(waiverIndex => props.league.waiverPriorityOrder![waiverIndex] === teamSeason.teamId) : null;
            waiverPriority = waiverPriority ? waiverPriority + 1 : null;
      return teamCell(props.league.id!, teamSeason, index, props.joinEnabled, joinTeam, setViewingUser, navigate, waiverPriority, props.showWaiverPriority);
    });
    const tableContents = generateTable(teamCells);
    return <Box>
      <Typography variant="h5" component="div" color="text.secondary" sx={{ flexGrow: 1 }}>
        League Standings
      </Typography>
      {tableContents}
    </Box>;
  }

  return <Box>
    <Modal open={!!viewingUser} onClose={() => setViewingUser(undefined)} 
        aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description"
        sx={{margin: '5%'}}>
      <UserInfoCard userId={viewingUser?.id!} callback={() => setViewingUser(undefined)}/>
    </Modal>
    {getDivisionContent()}
    {getOverallContent()}
    <p className="error">{errorMessage}</p>
  </Box>;
}

function teamCell(leagueId: string, teamSeason: TeamSeason, index: number, joinEnabled: boolean, joinTeam: (team: Team) => void, setViewingUser: (user: User | undefined) => void, navigate: NavigateFunction, waiverPriority: number | null, showWaiverPriority : boolean) {
  const team : Team = {
    id: teamSeason.teamId,
    name: teamSeason.teamName,
    picture: teamSeason.teamPicture,
    owners: teamSeason.owners,
  }
  return <TableRow key={index}>
    <TableCell>
      <TeamDisplay leagueId={leagueId} team={team} emphasized={false} />
    </TableCell>
    <TableCell>{teamSeason.wins}-{teamSeason.losses}-{teamSeason.ties}</TableCell>
    <TableCell>{teamSeason.pointsScored ? teamSeason.pointsScored.toFixed(2) : "0.00"}</TableCell>
    <TableCell>{teamSeason.pointsAgainst ? teamSeason.pointsAgainst.toFixed(2) : "0.00"}</TableCell>
    <TableCell>
    {teamSeason.owners?.length ? 
      teamSeason.owners!.map((owner, ownerIndex) => {
      return <Button key={ownerIndex} onClick={() => setViewingUser(owner)}>
          <IconButton sx={{ p: 0 }}>
            <Avatar alt={owner?.username} src={owner?.picture} />
          </IconButton>
          {' ' + owner.username}
          <br/>
        </Button>
    }) : 
    joinEnabled ? 
      <Button onClick={() => joinTeam(team)}>Join</Button>:
      'No Owner'}</TableCell>
    {showWaiverPriority && <TableCell>{waiverPriority ? waiverPriority : '-'}</TableCell>}
  </TableRow>
}

export default AllTeamsForSeason;