import * as React from 'react';
import { EventActivity, Team } from '../../../sdk/model';
import {Box, Button} from '@mui/material';
import { TableRow } from '@mui/material';
import TableCell from '@mui/material/TableCell';
import DraftPickSelectionDisplay from './DraftPickSelectionDisplay';
import AuctionPickSelectionDisplay from './AuctionPickSelectionDisplay';
import TradeAssetDisplay from './TradeAssetDisplay';
import WaiverPickupDisplay from './WaiverPickupDisplay';
import WaiverClearDisplay from './WaiverClearDisplay';
import AcquisitionDropDisplay from './AcquisitionDropDisplay';
import FaabWinDisplay from './FaabWinDisplay';
import { useNavigate } from 'react-router-dom';
import TeamDisplay from '../team/TeamDisplay';

interface eventActivityDisplayProps {
  activity: EventActivity,
  teamMap: Map<string | undefined, Team>
}

function EventActivityDisplay(props: eventActivityDisplayProps) {
  const navigate = useNavigate();
  const eventTypeDisplayMap = new Map();
  eventTypeDisplayMap.set('draft_pick', activity => <DraftPickSelectionDisplay pick={activity} teamMap={props.teamMap} />)
  eventTypeDisplayMap.set('auction_pick', activity => <AuctionPickSelectionDisplay pick={activity} teamMap={props.teamMap} />)
  eventTypeDisplayMap.set('trade_player', activity => <TradeAssetDisplay asset={activity} teamMap={props.teamMap} />)
  eventTypeDisplayMap.set('trade_pick', activity => <TradeAssetDisplay asset={activity} teamMap={props.teamMap} />)
  eventTypeDisplayMap.set('waiver_pickup', activity => <WaiverPickupDisplay waiverPickup={activity} teamMap={props.teamMap} />)
  eventTypeDisplayMap.set('waiver_clear', activity => <WaiverClearDisplay waiverClear={activity} teamMap={props.teamMap} />)
  eventTypeDisplayMap.set('acquisition_drop', activity => <AcquisitionDropDisplay acquisitionDrop={activity} teamMap={props.teamMap} />)
  eventTypeDisplayMap.set('faab_win', activity => <FaabWinDisplay faabWin={activity} teamMap={props.teamMap} />)
  eventTypeDisplayMap.set('player_drop_activity', activity => <Box>Dropped to waivers by <TeamDisplay leagueId={props.activity.leagueId} team={props.teamMap.get(activity.teamId)!} emphasized={false} /></Box>)
  eventTypeDisplayMap.set('imported_player', activity => "Contract imported from CSV by commissioner")
  eventTypeDisplayMap.set('franchise_tag', activity => <Box>Franchise tagged by <TeamDisplay leagueId={props.activity.leagueId} team={props.teamMap.get(activity.teamId)!} emphasized={false} /></Box>)
  eventTypeDisplayMap.set('rookie_option_tag', activity => <Box>5th-year Rookie Option exercised by <TeamDisplay leagueId={props.activity.leagueId} team={props.teamMap.get(activity.teamId)!} emphasized={false} /></Box>)
  eventTypeDisplayMap.set('quick_sign_player', activity => <Box>
      <Button onClick={() => navigate('/league/' + activity.leagueId + '/playerAcquisitionEvent/' + activity.leagueEventId)}>Quick-Signed</Button>
      to a league-minimum deal by <TeamDisplay leagueId={props.activity.leagueId} team={props.teamMap.get(activity.teamId)!} emphasized={false} />
    </Box>)
  eventTypeDisplayMap.set('move_to_ir_activity', activity => <Box>Sent to IR by <TeamDisplay leagueId={props.activity.leagueId} team={props.teamMap.get(activity.teamId)!} emphasized={false} /></Box>)
  eventTypeDisplayMap.set('return_from_ir_activity', activity => <Box>Returned from IR by <TeamDisplay leagueId={props.activity.leagueId} team={props.teamMap.get(activity.teamId)!} emphasized={false} /></Box>)
  
  const eventDateTime = new Date(props.activity.updatedTimeStamp);
  return <TableRow>
    <TableCell>{eventDateTime.toLocaleString()}</TableCell>
    <TableCell>{eventTypeDisplayMap.get(props.activity.type)(props.activity)}</TableCell>
  </TableRow>;
}

export default EventActivityDisplay;