/* tslint:disable */
/* eslint-disable */
/**
 * fantasy-dynasty-app
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { FaabClaim } from '../model';
// @ts-ignore
import { PlayerClaim } from '../model';
// @ts-ignore
import { QuickSign } from '../model';
// @ts-ignore
import { TeamPlayerClaims } from '../model';
// @ts-ignore
import { WaiverClaim } from '../model';
/**
 * PlayerClaimApi - axios parameter creator
 * @export
 */
export const PlayerClaimApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Makes a claim for the given player\'s waived contract
         * @param {WaiverClaim} waiverClaim Information about the waiver claim being made
         * @param {string} [teamId] A unique identifier for a team
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        claimWaivedContract: async (waiverClaim: WaiverClaim, teamId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'waiverClaim' is not null or undefined
            assertParamExists('claimWaivedContract', 'waiverClaim', waiverClaim)
            const localVarPath = `/playerClaims/team/{teamId}/waiver`
                .replace(`{${"teamId"}}`, encodeURIComponent(String(teamId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(waiverClaim, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Gets all active player claims for a given team
         * @param {string} [teamId] A unique identifier for a team
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPlayerClaims: async (teamId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/playerClaims/team/{teamId}`
                .replace(`{${"teamId"}}`, encodeURIComponent(String(teamId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Makes a FAAB claim on the given player
         * @param {FaabClaim} faabClaim Information about the FAAB claim being made
         * @param {string} [teamId] A unique identifier for a team
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        makeFaabBid: async (faabClaim: FaabClaim, teamId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'faabClaim' is not null or undefined
            assertParamExists('makeFaabBid', 'faabClaim', faabClaim)
            const localVarPath = `/playerClaims/team/{teamId}/faab`
                .replace(`{${"teamId"}}`, encodeURIComponent(String(teamId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(faabClaim, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Signs a player to a league-minimum contract on gameday
         * @param {QuickSign} quickSign Information about the QuickSign
         * @param {string} [teamId] A unique identifier for a team
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        quickSignPlayer: async (quickSign: QuickSign, teamId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'quickSign' is not null or undefined
            assertParamExists('quickSignPlayer', 'quickSign', quickSign)
            const localVarPath = `/playerClaims/team/{teamId}/quickSign`
                .replace(`{${"teamId"}}`, encodeURIComponent(String(teamId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(quickSign, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Sets a new priority order for player claims
         * @param {TeamPlayerClaims} teamPlayerClaims The new Team Player Claims object with the new priority order
         * @param {string} [teamPlayerClaimsId] A unique identifier for a set of Team Player Claims
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTeamPlayerClaims: async (teamPlayerClaims: TeamPlayerClaims, teamPlayerClaimsId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'teamPlayerClaims' is not null or undefined
            assertParamExists('updateTeamPlayerClaims', 'teamPlayerClaims', teamPlayerClaims)
            const localVarPath = `/playerClaims/{teamPlayerClaimsId}`
                .replace(`{${"teamPlayerClaimsId"}}`, encodeURIComponent(String(teamPlayerClaimsId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(teamPlayerClaims, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PlayerClaimApi - functional programming interface
 * @export
 */
export const PlayerClaimApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PlayerClaimApiAxiosParamCreator(configuration)
    return {
        /**
         * Makes a claim for the given player\'s waived contract
         * @param {WaiverClaim} waiverClaim Information about the waiver claim being made
         * @param {string} [teamId] A unique identifier for a team
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async claimWaivedContract(waiverClaim: WaiverClaim, teamId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TeamPlayerClaims>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.claimWaivedContract(waiverClaim, teamId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Gets all active player claims for a given team
         * @param {string} [teamId] A unique identifier for a team
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPlayerClaims(teamId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TeamPlayerClaims>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPlayerClaims(teamId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Makes a FAAB claim on the given player
         * @param {FaabClaim} faabClaim Information about the FAAB claim being made
         * @param {string} [teamId] A unique identifier for a team
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async makeFaabBid(faabClaim: FaabClaim, teamId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TeamPlayerClaims>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.makeFaabBid(faabClaim, teamId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Signs a player to a league-minimum contract on gameday
         * @param {QuickSign} quickSign Information about the QuickSign
         * @param {string} [teamId] A unique identifier for a team
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async quickSignPlayer(quickSign: QuickSign, teamId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.quickSignPlayer(quickSign, teamId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Sets a new priority order for player claims
         * @param {TeamPlayerClaims} teamPlayerClaims The new Team Player Claims object with the new priority order
         * @param {string} [teamPlayerClaimsId] A unique identifier for a set of Team Player Claims
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateTeamPlayerClaims(teamPlayerClaims: TeamPlayerClaims, teamPlayerClaimsId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateTeamPlayerClaims(teamPlayerClaims, teamPlayerClaimsId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PlayerClaimApi - factory interface
 * @export
 */
export const PlayerClaimApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PlayerClaimApiFp(configuration)
    return {
        /**
         * Makes a claim for the given player\'s waived contract
         * @param {WaiverClaim} waiverClaim Information about the waiver claim being made
         * @param {string} [teamId] A unique identifier for a team
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        claimWaivedContract(waiverClaim: WaiverClaim, teamId?: string, options?: any): AxiosPromise<TeamPlayerClaims> {
            return localVarFp.claimWaivedContract(waiverClaim, teamId, options).then((request) => request(axios, basePath));
        },
        /**
         * Gets all active player claims for a given team
         * @param {string} [teamId] A unique identifier for a team
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPlayerClaims(teamId?: string, options?: any): AxiosPromise<Array<TeamPlayerClaims>> {
            return localVarFp.getPlayerClaims(teamId, options).then((request) => request(axios, basePath));
        },
        /**
         * Makes a FAAB claim on the given player
         * @param {FaabClaim} faabClaim Information about the FAAB claim being made
         * @param {string} [teamId] A unique identifier for a team
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        makeFaabBid(faabClaim: FaabClaim, teamId?: string, options?: any): AxiosPromise<TeamPlayerClaims> {
            return localVarFp.makeFaabBid(faabClaim, teamId, options).then((request) => request(axios, basePath));
        },
        /**
         * Signs a player to a league-minimum contract on gameday
         * @param {QuickSign} quickSign Information about the QuickSign
         * @param {string} [teamId] A unique identifier for a team
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        quickSignPlayer(quickSign: QuickSign, teamId?: string, options?: any): AxiosPromise<void> {
            return localVarFp.quickSignPlayer(quickSign, teamId, options).then((request) => request(axios, basePath));
        },
        /**
         * Sets a new priority order for player claims
         * @param {TeamPlayerClaims} teamPlayerClaims The new Team Player Claims object with the new priority order
         * @param {string} [teamPlayerClaimsId] A unique identifier for a set of Team Player Claims
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTeamPlayerClaims(teamPlayerClaims: TeamPlayerClaims, teamPlayerClaimsId?: string, options?: any): AxiosPromise<void> {
            return localVarFp.updateTeamPlayerClaims(teamPlayerClaims, teamPlayerClaimsId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PlayerClaimApi - object-oriented interface
 * @export
 * @class PlayerClaimApi
 * @extends {BaseAPI}
 */
export class PlayerClaimApi extends BaseAPI {
    /**
     * Makes a claim for the given player\'s waived contract
     * @param {WaiverClaim} waiverClaim Information about the waiver claim being made
     * @param {string} [teamId] A unique identifier for a team
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlayerClaimApi
     */
    public claimWaivedContract(waiverClaim: WaiverClaim, teamId?: string, options?: AxiosRequestConfig) {
        return PlayerClaimApiFp(this.configuration).claimWaivedContract(waiverClaim, teamId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Gets all active player claims for a given team
     * @param {string} [teamId] A unique identifier for a team
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlayerClaimApi
     */
    public getPlayerClaims(teamId?: string, options?: AxiosRequestConfig) {
        return PlayerClaimApiFp(this.configuration).getPlayerClaims(teamId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Makes a FAAB claim on the given player
     * @param {FaabClaim} faabClaim Information about the FAAB claim being made
     * @param {string} [teamId] A unique identifier for a team
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlayerClaimApi
     */
    public makeFaabBid(faabClaim: FaabClaim, teamId?: string, options?: AxiosRequestConfig) {
        return PlayerClaimApiFp(this.configuration).makeFaabBid(faabClaim, teamId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Signs a player to a league-minimum contract on gameday
     * @param {QuickSign} quickSign Information about the QuickSign
     * @param {string} [teamId] A unique identifier for a team
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlayerClaimApi
     */
    public quickSignPlayer(quickSign: QuickSign, teamId?: string, options?: AxiosRequestConfig) {
        return PlayerClaimApiFp(this.configuration).quickSignPlayer(quickSign, teamId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Sets a new priority order for player claims
     * @param {TeamPlayerClaims} teamPlayerClaims The new Team Player Claims object with the new priority order
     * @param {string} [teamPlayerClaimsId] A unique identifier for a set of Team Player Claims
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlayerClaimApi
     */
    public updateTeamPlayerClaims(teamPlayerClaims: TeamPlayerClaims, teamPlayerClaimsId?: string, options?: AxiosRequestConfig) {
        return PlayerClaimApiFp(this.configuration).updateTeamPlayerClaims(teamPlayerClaims, teamPlayerClaimsId, options).then((request) => request(this.axios, this.basePath));
    }
}
