import * as React from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import MenuIcon from '@mui/icons-material/Menu';
import { Drawer, List, ListItem, ListItemButton, ListItemText, IconButton, Typography } from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import Divider from '@mui/material/Divider';
import { useAppSelector } from '../../../app/hooks'
import { useLoadLeagueData } from '../../../app/dataLoaderHooks';
import { League } from '../../../sdk/model';
import { LeaguePhase } from '../../util/enum/LeaguePhase';
import TeamView from './TeamView';
import { useUpdateLeagueDataFromMessage } from '../../../app/dataLoaderHooks';
import { Properties } from '../../../Properties';
import useWebSocketListener from '../../web-sockets/WebSocketListener';

function LeagueMenu({ onSideNavOpenChange }) {
  const {leagueId} = useParams();

  // Navigator/Dispatch
  const navigate = useNavigate();

  // State  
  const user = useAppSelector((state) => state.user);
  const [sideNavOpen, setSideNavOpen] = React.useState(true);

  const [isLoadComplete, setLoadComplete] = React.useState(false);
  const [isException, setIsException] = React.useState(false);

  const league : League|undefined = useLoadLeagueData({leagueId: leagueId, userId: user.id, loadBasicDraftData: true,
                                                              loadFullDraftData: false, loadContractData: false,
                                                              loadBasicAuctionData: true, loadFullAuctionData: false,
                                                              loadRosterData: false, 
                                                              isException: isException, setIsException: setIsException,
                                                              isLoadComplete: isLoadComplete, setLoadComplete: setLoadComplete});
  
  React.useEffect(() => {
    onSideNavOpenChange(sideNavOpen);
  }, [sideNavOpen]);

  const leagueWebSocketUrl = Properties.websocketsUrl + '/ws/league/' + user.username + '/' + leagueId + '/' + league?.phase;
  useWebSocketListener(leagueWebSocketUrl, useUpdateLeagueDataFromMessage);
  
  if(isException) {
    return <Typography color="red">There was an error loading the league</Typography>
  } else if(!isLoadComplete) {
    return <Typography color="white">Loading league data..</Typography>
  }
  
  if (!league) {
    return <p>Loading...</p>
  }
  
  const commish = league?.commissioners?.some(commish => commish.id === user.id);
  function toggleSideNav() {
  setSideNavOpen(!sideNavOpen);
  }
  const drawerWidth = 250;
  return <Box>
    <IconButton
        color="inherit"
        aria-label="open league menu"
        onClick={toggleSideNav}
        edge="start"
        sx={{ mr: 2, float: 'left', marginLeft: 1, marginRight: -40, ...(sideNavOpen && { display: 'none' }) }}>
      <MenuIcon />
    </IconButton>
    <Drawer
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: drawerWidth + 1,
          boxSizing: 'border-box',
        },
      }}
        anchor='left'
        variant="persistent"
        open={sideNavOpen}
        onClose={toggleSideNav}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        PaperProps={{
          sx : {
        position: 'absolute'
        }
        }}>
      <IconButton onClick={toggleSideNav}>
        <ChevronLeftIcon />
        <Typography
            variant="body2"
            align="center"
            color="text.primary"
            gutterBottom>
          League Menu
      </Typography>
      <Typography variant="body2" align="center" color="text.secondary" gutterBottom>
        ({LeaguePhase[league!.phase]})
      </Typography>
      </IconButton>
      <Divider />
      <Box sx={{ width: drawerWidth }}>
        <List>
          <ListItem>
            <ListItemButton onClick={() => navigate('/league/' + league.id)}>
              <ListItemText primary="Home" />
            </ListItemButton>
          </ListItem>
          <ListItem>
            <ListItemButton onClick={() => navigate('/league/' + league.id + '/week')}>
              <ListItemText primary="Current Week" />
            </ListItemButton>
          </ListItem>
          <ListItem>
            <ListItemButton onClick={() => navigate('/league/' + league.id + '/matchup')}>
              <ListItemText primary="Current Match" />
            </ListItemButton>
          </ListItem>
          <ListItem>
            <ListItemButton onClick={() => navigate('/league/' + league.id + '/players')}>
              <ListItemText primary="Players" />
            </ListItemButton>
          </ListItem>
          <ListItem>
            <ListItemButton onClick={() => navigate('/league/' + league.id + '/contracts')}>
              <ListItemText primary="Contracts" />
            </ListItemButton>
          </ListItem>
          <ListItem>
            <ListItemButton onClick={() => navigate('/league/' + league.id + '/schedule')}>
              <ListItemText primary="Schedule" />
            </ListItemButton>
          </ListItem>
          <ListItem>
            <ListItemButton onClick={() => navigate('/league/' + league.id + '/picks')}>
              <ListItemText primary="Draft Picks" />
            </ListItemButton>
          </ListItem>
          <ListItem>
            <ListItemButton onClick={() => navigate('/league/' + league.id + '/transactions')}>
              <ListItemText primary="Transactions" />
            </ListItemButton>
          </ListItem>
        </List>
        {Object.entries(league!.teams)
            .filter(([teamIndex, team]) => team?.owners?.some(owner => owner.id === user.id))
            .map(([teamIndex, team]) => <TeamView key={teamIndex} leagueId={league!.id!} team={team} />
        )}
        <Divider />
        <List>
          <ListItem>
            League History
          </ListItem>
          <ListItem>
            <ListItemButton onClick={() => navigate('/league/' + league.id + '/draftHistory')}>
              <ListItemText primary="Draft History" />
            </ListItemButton>
          </ListItem>
          <ListItem>
            <ListItemButton onClick={() => navigate('/league/' + league.id + '/auctionHistory')}>
              <ListItemText primary="Auction History" />
            </ListItemButton>
          </ListItem>
          <ListItem>
            <ListItemButton onClick={() => navigate('/league/' + league.id + '/history')}>
              <ListItemText primary="League History" />
            </ListItemButton>
          </ListItem>
        </List>
        {commish ?
      <Box>
          <Divider />
          <List>
            <ListItem>
              <ListItemButton onClick={() => navigate('/league/' + league.id + '/commish')}>
                <ListItemText primary="Commissioner Tools" />
              </ListItemButton>
            </ListItem>
          </List>
        </Box> : null
      }
      </Box>
    </Drawer>
  </Box>;
}

export default LeagueMenu;