import * as React from 'react';
import { Player } from '../../../sdk/model';
import {Button} from '@mui/material';
import {isPlayerGameTodayOrEarlier} from '../../util/PlayerDataUtil';
import { useNavigate } from 'react-router-dom';

interface bidOnPlayerButtonProps {
  leagueId : string,
  player : Player,
  teamId : string
}

function BidOnPlayerButton(props: bidOnPlayerButtonProps) {
  const navigate = useNavigate();
  
  return isPlayerGameTodayOrEarlier(props.player) ? 
  	<Button color="primary" variant="contained" sx={{marginLeft: 2}} onClick={() => navigate('/league/' + props.leagueId + '/team/' + props.teamId + '/quickSign/' + props.player.id)}>Add</Button> : 
    <Button color="primary" variant="contained" sx={{marginLeft: 2}} onClick={() => navigate('/league/' + props.leagueId + '/team/' + props.teamId + '/faab/' + props.player.id)}>Bid</Button>;
}

export default BidOnPlayerButton;
