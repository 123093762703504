/* tslint:disable */
/* eslint-disable */
/**
 * fantasy-dynasty-app
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { Contract } from '../model';
// @ts-ignore
import { ContractStatus } from '../model';
// @ts-ignore
import { EventActivity } from '../model';
// @ts-ignore
import { TagType } from '../model';
// @ts-ignore
import { Team } from '../model';
/**
 * ContractApi - axios parameter creator
 * @export
 */
export const ContractApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Finalizes the given tag on the given player in the given league
         * @param {string} [leagueId] A unique identifier for a league
         * @param {string} [playerId] A unique identifier for a player
         * @param {TagType} [tagType] the type of tag to place on the given player
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        finalizePlayerContractTag: async (leagueId?: string, playerId?: string, tagType?: TagType, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/contracts/{leagueId}/player/{playerId}/tag/{tagType}/finalize`
                .replace(`{${"leagueId"}}`, encodeURIComponent(String(leagueId)))
                .replace(`{${"playerId"}}`, encodeURIComponent(String(playerId)))
                .replace(`{${"tagType"}}`, encodeURIComponent(String(tagType)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get all event history associated with a contract
         * @param {string} [contractId] A unique identifier for a contract
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContractHistory: async (contractId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/contracts/{contractId}/eventHistory`
                .replace(`{${"contractId"}}`, encodeURIComponent(String(contractId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retreives all contracts by ids
         * @param {Array<string>} ids The ids of contracts to retreive
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContractsByIds: async (ids: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'ids' is not null or undefined
            assertParamExists('getContractsByIds', 'ids', ids)
            const localVarPath = `/contracts/byIds`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (ids) {
                localVarQueryParameter['ids'] = ids;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get all contracts in a league with the given status
         * @param {string} [leagueId] A unique identifier for a league
         * @param {ContractStatus} [status] A unique identifier for a player
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContractsInStatus: async (leagueId?: string, status?: ContractStatus, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/contracts/{leagueId}/{status}`
                .replace(`{${"leagueId"}}`, encodeURIComponent(String(leagueId)))
                .replace(`{${"status"}}`, encodeURIComponent(String(status)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get all contracts associated with a player in a league
         * @param {string} [leagueId] A unique identifier for a league
         * @param {string} [playerId] A unique identifier for a player
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPlayerContracts: async (leagueId?: string, playerId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/contracts/{leagueId}/player/{playerId}`
                .replace(`{${"leagueId"}}`, encodeURIComponent(String(leagueId)))
                .replace(`{${"playerId"}}`, encodeURIComponent(String(playerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Imports contracts into a league via CSV
         * @param {string} [leagueId] A unique identifier for a league
         * @param {number} [seasonYear] the season from which the contract terms take place
         * @param {File} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        importContracts: async (leagueId?: string, seasonYear?: number, body?: File, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/contracts/{leagueId}/{seasonYear}/import`
                .replace(`{${"leagueId"}}`, encodeURIComponent(String(leagueId)))
                .replace(`{${"seasonYear"}}`, encodeURIComponent(String(seasonYear)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'text/csv';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Marks the given player with the given tag type in the given league
         * @param {string} [leagueId] A unique identifier for a league
         * @param {string} [playerId] A unique identifier for a player
         * @param {TagType} [tagType] the type of tag to place on the given player
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        markPlayerForContractTag: async (leagueId?: string, playerId?: string, tagType?: TagType, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/contracts/{leagueId}/player/{playerId}/tag/{tagType}`
                .replace(`{${"leagueId"}}`, encodeURIComponent(String(leagueId)))
                .replace(`{${"playerId"}}`, encodeURIComponent(String(playerId)))
                .replace(`{${"tagType"}}`, encodeURIComponent(String(tagType)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Removes a marked tag of the given type from the given player in the given player
         * @param {string} [leagueId] A unique identifier for a league
         * @param {string} [playerId] A unique identifier for a player
         * @param {TagType} [tagType] the type of tag to place on the given player
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeTag: async (leagueId?: string, playerId?: string, tagType?: TagType, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/contracts/{leagueId}/player/{playerId}/tag/{tagType}`
                .replace(`{${"leagueId"}}`, encodeURIComponent(String(leagueId)))
                .replace(`{${"playerId"}}`, encodeURIComponent(String(playerId)))
                .replace(`{${"tagType"}}`, encodeURIComponent(String(tagType)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ContractApi - functional programming interface
 * @export
 */
export const ContractApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ContractApiAxiosParamCreator(configuration)
    return {
        /**
         * Finalizes the given tag on the given player in the given league
         * @param {string} [leagueId] A unique identifier for a league
         * @param {string} [playerId] A unique identifier for a player
         * @param {TagType} [tagType] the type of tag to place on the given player
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async finalizePlayerContractTag(leagueId?: string, playerId?: string, tagType?: TagType, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.finalizePlayerContractTag(leagueId, playerId, tagType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get all event history associated with a contract
         * @param {string} [contractId] A unique identifier for a contract
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getContractHistory(contractId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<EventActivity>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getContractHistory(contractId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retreives all contracts by ids
         * @param {Array<string>} ids The ids of contracts to retreive
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getContractsByIds(ids: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Contract>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getContractsByIds(ids, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get all contracts in a league with the given status
         * @param {string} [leagueId] A unique identifier for a league
         * @param {ContractStatus} [status] A unique identifier for a player
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getContractsInStatus(leagueId?: string, status?: ContractStatus, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Contract>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getContractsInStatus(leagueId, status, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get all contracts associated with a player in a league
         * @param {string} [leagueId] A unique identifier for a league
         * @param {string} [playerId] A unique identifier for a player
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPlayerContracts(leagueId?: string, playerId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Contract>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPlayerContracts(leagueId, playerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Imports contracts into a league via CSV
         * @param {string} [leagueId] A unique identifier for a league
         * @param {number} [seasonYear] the season from which the contract terms take place
         * @param {File} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async importContracts(leagueId?: string, seasonYear?: number, body?: File, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.importContracts(leagueId, seasonYear, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Marks the given player with the given tag type in the given league
         * @param {string} [leagueId] A unique identifier for a league
         * @param {string} [playerId] A unique identifier for a player
         * @param {TagType} [tagType] the type of tag to place on the given player
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async markPlayerForContractTag(leagueId?: string, playerId?: string, tagType?: TagType, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Team>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.markPlayerForContractTag(leagueId, playerId, tagType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Removes a marked tag of the given type from the given player in the given player
         * @param {string} [leagueId] A unique identifier for a league
         * @param {string} [playerId] A unique identifier for a player
         * @param {TagType} [tagType] the type of tag to place on the given player
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async removeTag(leagueId?: string, playerId?: string, tagType?: TagType, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Team>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.removeTag(leagueId, playerId, tagType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ContractApi - factory interface
 * @export
 */
export const ContractApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ContractApiFp(configuration)
    return {
        /**
         * Finalizes the given tag on the given player in the given league
         * @param {string} [leagueId] A unique identifier for a league
         * @param {string} [playerId] A unique identifier for a player
         * @param {TagType} [tagType] the type of tag to place on the given player
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        finalizePlayerContractTag(leagueId?: string, playerId?: string, tagType?: TagType, options?: any): AxiosPromise<void> {
            return localVarFp.finalizePlayerContractTag(leagueId, playerId, tagType, options).then((request) => request(axios, basePath));
        },
        /**
         * Get all event history associated with a contract
         * @param {string} [contractId] A unique identifier for a contract
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContractHistory(contractId?: string, options?: any): AxiosPromise<Array<EventActivity>> {
            return localVarFp.getContractHistory(contractId, options).then((request) => request(axios, basePath));
        },
        /**
         * Retreives all contracts by ids
         * @param {Array<string>} ids The ids of contracts to retreive
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContractsByIds(ids: Array<string>, options?: any): AxiosPromise<Array<Contract>> {
            return localVarFp.getContractsByIds(ids, options).then((request) => request(axios, basePath));
        },
        /**
         * Get all contracts in a league with the given status
         * @param {string} [leagueId] A unique identifier for a league
         * @param {ContractStatus} [status] A unique identifier for a player
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContractsInStatus(leagueId?: string, status?: ContractStatus, options?: any): AxiosPromise<Array<Contract>> {
            return localVarFp.getContractsInStatus(leagueId, status, options).then((request) => request(axios, basePath));
        },
        /**
         * Get all contracts associated with a player in a league
         * @param {string} [leagueId] A unique identifier for a league
         * @param {string} [playerId] A unique identifier for a player
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPlayerContracts(leagueId?: string, playerId?: string, options?: any): AxiosPromise<Array<Contract>> {
            return localVarFp.getPlayerContracts(leagueId, playerId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Imports contracts into a league via CSV
         * @param {string} [leagueId] A unique identifier for a league
         * @param {number} [seasonYear] the season from which the contract terms take place
         * @param {File} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        importContracts(leagueId?: string, seasonYear?: number, body?: File, options?: any): AxiosPromise<void> {
            return localVarFp.importContracts(leagueId, seasonYear, body, options).then((request) => request(axios, basePath));
        },
        /**
         * Marks the given player with the given tag type in the given league
         * @param {string} [leagueId] A unique identifier for a league
         * @param {string} [playerId] A unique identifier for a player
         * @param {TagType} [tagType] the type of tag to place on the given player
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        markPlayerForContractTag(leagueId?: string, playerId?: string, tagType?: TagType, options?: any): AxiosPromise<Team> {
            return localVarFp.markPlayerForContractTag(leagueId, playerId, tagType, options).then((request) => request(axios, basePath));
        },
        /**
         * Removes a marked tag of the given type from the given player in the given player
         * @param {string} [leagueId] A unique identifier for a league
         * @param {string} [playerId] A unique identifier for a player
         * @param {TagType} [tagType] the type of tag to place on the given player
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeTag(leagueId?: string, playerId?: string, tagType?: TagType, options?: any): AxiosPromise<Team> {
            return localVarFp.removeTag(leagueId, playerId, tagType, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ContractApi - object-oriented interface
 * @export
 * @class ContractApi
 * @extends {BaseAPI}
 */
export class ContractApi extends BaseAPI {
    /**
     * Finalizes the given tag on the given player in the given league
     * @param {string} [leagueId] A unique identifier for a league
     * @param {string} [playerId] A unique identifier for a player
     * @param {TagType} [tagType] the type of tag to place on the given player
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContractApi
     */
    public finalizePlayerContractTag(leagueId?: string, playerId?: string, tagType?: TagType, options?: AxiosRequestConfig) {
        return ContractApiFp(this.configuration).finalizePlayerContractTag(leagueId, playerId, tagType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get all event history associated with a contract
     * @param {string} [contractId] A unique identifier for a contract
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContractApi
     */
    public getContractHistory(contractId?: string, options?: AxiosRequestConfig) {
        return ContractApiFp(this.configuration).getContractHistory(contractId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retreives all contracts by ids
     * @param {Array<string>} ids The ids of contracts to retreive
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContractApi
     */
    public getContractsByIds(ids: Array<string>, options?: AxiosRequestConfig) {
        return ContractApiFp(this.configuration).getContractsByIds(ids, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get all contracts in a league with the given status
     * @param {string} [leagueId] A unique identifier for a league
     * @param {ContractStatus} [status] A unique identifier for a player
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContractApi
     */
    public getContractsInStatus(leagueId?: string, status?: ContractStatus, options?: AxiosRequestConfig) {
        return ContractApiFp(this.configuration).getContractsInStatus(leagueId, status, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get all contracts associated with a player in a league
     * @param {string} [leagueId] A unique identifier for a league
     * @param {string} [playerId] A unique identifier for a player
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContractApi
     */
    public getPlayerContracts(leagueId?: string, playerId?: string, options?: AxiosRequestConfig) {
        return ContractApiFp(this.configuration).getPlayerContracts(leagueId, playerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Imports contracts into a league via CSV
     * @param {string} [leagueId] A unique identifier for a league
     * @param {number} [seasonYear] the season from which the contract terms take place
     * @param {File} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContractApi
     */
    public importContracts(leagueId?: string, seasonYear?: number, body?: File, options?: AxiosRequestConfig) {
        return ContractApiFp(this.configuration).importContracts(leagueId, seasonYear, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Marks the given player with the given tag type in the given league
     * @param {string} [leagueId] A unique identifier for a league
     * @param {string} [playerId] A unique identifier for a player
     * @param {TagType} [tagType] the type of tag to place on the given player
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContractApi
     */
    public markPlayerForContractTag(leagueId?: string, playerId?: string, tagType?: TagType, options?: AxiosRequestConfig) {
        return ContractApiFp(this.configuration).markPlayerForContractTag(leagueId, playerId, tagType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Removes a marked tag of the given type from the given player in the given player
     * @param {string} [leagueId] A unique identifier for a league
     * @param {string} [playerId] A unique identifier for a player
     * @param {TagType} [tagType] the type of tag to place on the given player
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContractApi
     */
    public removeTag(leagueId?: string, playerId?: string, tagType?: TagType, options?: AxiosRequestConfig) {
        return ContractApiFp(this.configuration).removeTag(leagueId, playerId, tagType, options).then((request) => request(this.axios, this.basePath));
    }
}
