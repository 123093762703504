import * as React from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useAppSelector } from '../../../app/hooks';
import { useLoadLeagueData, useAddUpdatePlayerClaim } from '../../../app/dataLoaderHooks';
import { League, Team, TradeProposalPlayerActionEnum, TradeProposalPlayer, FaabClaim, TeamPlayerClaims } from '../../../sdk/model';
import {Box, Typography, Button, TextField} from '@mui/material';
import TradeTeamViewer from '../trade/TradeTeamViewer';
import TradeDetails from '../trade/TradeDetails';
import { toSalary, getNewSalary, getSalaryForSeason } from '../../util/SalaryDisplay';
import PlayerCardLink from '../../cards/playerCardLink';
import { playerClaimApi } from '../../../adapters/APIExporter';
import { MINIMUM_ONE_YEAR_SALARY, MINIMUM_BID_INCREMENT } from '../../util/ContractUtils';
import MoneyFormat from '../../util/MoneyFormat';
import { getRosterSize, getMaxTeamSize } from '../../util/TeamUtils';
import {isPlayerLocked, getNextDayStart} from '../../util/PlayerDataUtil';

function DropForFaabClaim() {
  const navigate = useNavigate();
  const {leagueId, teamId, playerId} = useParams();
  
  const [isException, setIsException] = React.useState(false);
  const [isLoadComplete, setLoadComplete] = React.useState(false);
  const [dropPlayerIds, setDropPlayerIds] = React.useState<string[]>([]);
  const [bidAmount, setBidAmount] = React.useState<number>(MINIMUM_BID_INCREMENT);
  const [navigateAway, setNavigateAway] = React.useState(false);
  const [newTeamPlayerClaims, setNewTeamPlayerClaims] = React.useState<TeamPlayerClaims | undefined>(undefined);
  const [errors, setErrors] = React.useState<Array<string>>([]);
  
  const user = useAppSelector((state) => state.user);
  
  const league : League|undefined = useLoadLeagueData({leagueId: leagueId, userId: user.id, loadBasicDraftData: false,
                                                            loadFullDraftData: false, loadContractData: true,
                                                            loadBasicAuctionData: false, loadFullAuctionData: false,
                                                            loadRosterData: false, loadRosterFor: [teamId!], loadWaivedContracts: false,
                                                            isException: isException, setIsException: setIsException,
                                                            isLoadComplete: isLoadComplete, setLoadComplete: setLoadComplete});
  
  const teamMap : Map<string | undefined, Team> = new Map(league?.teams?.map((team) => [team.id, team]));                                           
  const myTeam = teamMap.get(teamId);
  
  const playerDataLoad = useAppSelector((state) => state.playerDataLoad );
  const playerMap = new Map(playerDataLoad.flatMap(team => team.players).map((player) => [player?.id, player]));
  
  useAddUpdatePlayerClaim(teamId!, newTeamPlayerClaims?.id, newTeamPlayerClaims);
  
  if (newTeamPlayerClaims) {
    setNewTeamPlayerClaims(undefined);
  }
  
  const currentWeek = league?.currentSeason?.currentWeek || 0;
  const firstWeekOfContract = Math.max(currentWeek, 1);
  const finalWeek = league?.currentSeason?.finalWeek || 0;
  const numberOfWeeks = finalWeek - firstWeekOfContract + 1;
  
  React.useEffect(() => {
    if (navigateAway) {
      navigate('/league/' + leagueId);
    }
  }, [navigateAway]);
  
  function handleDropChange(playerId: string, fromTeamId: string, action: string) {
    const newDropPlayerIds = [...dropPlayerIds];
    if (action === 'drop') {
      newDropPlayerIds.push(playerId);
    } else {
      const playerIndex = dropPlayerIds.indexOf(playerId);
      newDropPlayerIds.splice(playerIndex, 1);
    }
    setDropPlayerIds(newDropPlayerIds);
  }
  
  function setErrorsAndFocus(errors) {
    setErrors(errors);
    document.getElementById("errors")!.focus();
  }
  
  function makeFaabClaim() {
    const claim : FaabClaim = {
      type: 'faab_claim',
      playerId: playerId,
      bidAmount: bidAmount,
      dropPlayerIds: dropPlayerIds
    }
    playerClaimApi.makeFaabBid(claim, teamId).then(response => {
      setNewTeamPlayerClaims(response.data);
      setNavigateAway(true);
    }).catch(error => {
      let errors = [error.response.data.message];
      setErrorsAndFocus(errors);
    });
  }
  
  if (!isLoadComplete || !myTeam?.rosterLoaded || !leagueId || !teamId || !playerId) {
    return <p>Loading...</p>
  }
  
  const players : TradeProposalPlayer[] = dropPlayerIds.map(dropPlayerId => {
    return {
      playerId: dropPlayerId,
      fromTeamId: teamId,
      action: TradeProposalPlayerActionEnum.Drop
    }
  })
  
  players.push({
    playerId: playerId,
    toTeamId: teamId,
    action: TradeProposalPlayerActionEnum.Trade
  });
  
  const proposalPlayerMap = new Map(players.map(player => [player.playerId, player]));
  const teamPlayerMap = new Map(Object.values(myTeam.playerRoster!).flatMap(playerList => 
    playerList.filter(player => player).map(player => [player.playerId, player])
  ));
  const adjustedBidAmount = bidAmount < MINIMUM_ONE_YEAR_SALARY ? MINIMUM_ONE_YEAR_SALARY :Math.floor(bidAmount / MINIMUM_BID_INCREMENT) * MINIMUM_BID_INCREMENT;
  const actualBidValue = adjustedBidAmount * numberOfWeeks / league!.currentSeason!.finalWeek!;
  const weeklyPayments = actualBidValue / numberOfWeeks;
  teamPlayerMap.set(playerId, {
    contract: {
      seasons: [{
        season_year: league!.currentSeasonYear,
        annual_salary: actualBidValue,
        weeks: [...Array(numberOfWeeks).keys()].map(weekIndex => weekIndex + firstWeekOfContract).map(weekNumber => {
          return {
            fullSalary: weeklyPayments,
            guaranteedSalary: 0,
            payments: [{
              teamId: teamId,
              weekNumber: weekNumber,
              price: weeklyPayments,
              completed: false
            }]
          }
        })
      }]
    }
  });
  
  const rosterSize = getRosterSize(myTeam);
  const newRosterSize = players.reduce((partialSum, tradePlayer) => {
      const owningTeam = teamMap.get(tradePlayer.fromTeamId);
      const isIr = owningTeam?.playerRoster![RosterPositionEnum.Ir].some(player => player && player.playerId === tradePlayer.playerId);
      return partialSum + (tradePlayer.toTeamId === myTeam?.id ? 1 : (tradePlayer.fromTeamId === myTeam?.id && !isIr ? -1 : 0));
    }, rosterSize);
  const maxRosterSize = getMaxTeamSize(league!);
  const newEmptyRosterSpots = maxRosterSize - newRosterSize;
  const overRosterLimit = newEmptyRosterSpots < 0;
  const currentSalary = getSalaryForSeason(myTeam!.paymentsBySeason![league?.currentSeasonYear!]);
  const newSalary = getNewSalary(myTeam!, teamPlayerMap, new Map(), currentSalary, players, [], league?.currentSeasonYear!, 0, newEmptyRosterSpots, league?.currentSeason)
  const salaryCap = league?.currentSeason?.nflSeason?.salaryCap!;
  const overCap = newSalary > salaryCap;
  
  const player = playerMap.get(playerId)!;
  const earliestLockDate = isPlayerLocked(player) ? null : getNextDayStart();
  
  let errorInfo = errors.map((error, index) => {
    return (<p className='error' key={'error_' + index}>{error}</p>);
  });
  return <Box>
    <Typography variant="h5" component="div" color="text.secondary" sx={{ flexGrow: 1 }}>
      FAAB Claim for <PlayerCardLink player={player} leagueId={leagueId!} />
    </Typography>
    <br/>
    <TextField name="bidAmount" label="Bid (at full-season value) " value={bidAmount}
            InputProps={{inputProps: {min: MINIMUM_ONE_YEAR_SALARY, step:MINIMUM_BID_INCREMENT}, inputComponent: MoneyFormat as any}} onChange={(event) => setBidAmount(event.target.value)} onBlur={() => setBidAmount(adjustedBidAmount)} />
    <p>Actual Contract Value: {toSalary(actualBidValue)}</p>
    <Typography variant="h6" component="div" color="text.secondary" sx={{ flexGrow: 1 }}>
      Optionally, select player(s) to conditionally drop:
    </Typography>
    <Box tabIndex="0" id="errors">{errorInfo}</Box>
    <TradeTeamViewer team={myTeam} currentSeason={league?.currentSeason?.seasonYear!}
            playerMap={playerMap} teamMap={new Map([[teamId, myTeam]])} userId={user?.id} myTeam={true} 
            otherTeamIds={[]} handleTradeProposalChange={handleDropChange}
            proposalPlayerMap={proposalPlayerMap} proposalPickMap={undefined} earliestLockDate={earliestLockDate} />
    <Typography variant="h6" component="div" color="text.secondary" sx={{ flexGrow: 1 }}>
      Full Details
    </Typography>
    <TradeDetails leagueId={leagueId} teams={[myTeam]} currentSeason={league?.currentSeasonYear} players={players} picks={[]} playerMap={playerMap} teamMap={teamMap} teamPlayerMap={teamPlayerMap} pickMap={new Map()} salaryCap={league!.currentSeason!.nflSeason!.salaryCap!} maxRosterSize={maxRosterSize} />
    <Button color="primary" variant="contained" onClick={makeFaabClaim} disabled={overCap || overRosterLimit}>Complete Claim</Button>
  </Box>
}

export default DropForFaabClaim;