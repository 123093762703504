import * as React from 'react'
import { useAppSelector } from '../../../app/hooks';
import { useLoadLeagueData } from '../../../app/dataLoaderHooks';
import {Box, Dialog, DialogTitle, IconButton, DialogContent, Typography, DialogActions, Button} from '@mui/material';
import Close from "@mui/icons-material/Close";
import { League, Player, Contract, Team, TradeProposalPlayer, TradeProposalPlayerActionEnum } from '../../../sdk/model';
import { teamApi } from '../../../adapters/APIExporter';
import TradeDetails from '../trade/TradeDetails';
import {isPlayerLocked} from '../../util/PlayerDataUtil';
import { getMaxTeamSize } from '../../util/TeamUtils';

interface dropPlayerButtonProps {
  leagueId: string,
  player: Player,
  contract: Contract,
  currentSeasonYear: number,
  team: Team,
  callback: Function,
  sx
}

function DropPlayerButton(props: dropPlayerButtonProps) {
  const [confirming, setConfirming] = React.useState(false);
  const [isException, setIsException] = React.useState(false);
  const [isLoadComplete, setLoadComplete] = React.useState(false);
  const [errors, setErrors] = React.useState([]);
  function dropPlayer() {
    teamApi.removePlayerFromTeam(props.player.id, props.team.id).then(response => {
      props.callback(response.data);
      setConfirming(false);
    }).catch(error => {
      setErrors([error.response.data.message]);
    });
  }
  const players : TradeProposalPlayer[] = [{
    playerId: props.player.id,
    fromTeamId: props.team.id,
    action: TradeProposalPlayerActionEnum.Drop
  }]
  const user = useAppSelector((state) => state.user);
  
  const league : League|undefined = useLoadLeagueData({leagueId: props.leagueId, userId: user.id, loadBasicDraftData: false,
                                                            loadFullDraftData: false, loadContractData: true,
                                                            loadBasicAuctionData: false, loadFullAuctionData: false,
                                                            loadRosterData: false, loadWaivedContracts: false,
                                                            isException: isException, setIsException: setIsException,
                                                            isLoadComplete: isLoadComplete, setLoadComplete: setLoadComplete});
  const playerMap = new Map([[props.player.id, props.player]]);
  const teamMap = new Map([[props.team.id, props.team]]);
  const teamPlayerMap = new Map(Object.values(props.team.playerRoster!).flatMap(playerList => 
    playerList.filter(player => player).map(player => [player.playerId, player])
  ));
  const maxRosterSize = getMaxTeamSize(league!);
  const buttonDisabled = isPlayerLocked(props.player);
  let errorInfo = errors.map((error, index) => {
    return (<p className='error' key={'error_' + index}>{error}</p>);
  });
  return <React.Fragment>
    <Dialog open={confirming} maxWidth="lg" fullWidth>
      <DialogTitle>Drop Player?</DialogTitle>
      <Box position="absolute" top={0} right={0}>
        <IconButton onClick={() => setConfirming(false)}>
          <Close />
        </IconButton>
      </Box>
      <DialogContent>
        {errorInfo}
        <Typography>Are you sure you want to cut {props.player.abbr_name}?  This will have the following impact on your team's salary cap if the contract is not claimed on waivers:
          <TradeDetails leagueId={props.leagueId} teams={[props.team]} currentSeason={props.currentSeasonYear} players={players} picks={[]} playerMap={playerMap} teamMap={teamMap} teamPlayerMap={teamPlayerMap} pickMap={new Map()} salaryCap={league!.currentSeason!.nflSeason!.salaryCap!} maxRosterSize={maxRosterSize} />
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button color="primary" variant="contained" onClick={() => setConfirming(false)}>
          Cancel
        </Button>
        <Button color="secondary" variant="contained" onClick={dropPlayer}>
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
    <Button sx={props.sx} color="primary" variant="contained" disabled={buttonDisabled} onClick={() => setConfirming(true)}>Drop Player</Button>
  </React.Fragment>
}

export default DropPlayerButton;