import * as React from 'react';
import { League } from '../../../../sdk/model';
import {Box, Typography, Button} from '@mui/material';
import {positionOrder} from '../../../util/PositionOrder';
import RosterConfig from './RosterConfig';
import { leagueApi } from '../../../../adapters/APIExporter'

interface rosterSettingsProps {
  league: League
}

function RosterSettings(props: rosterSettingsProps) {
  const [editMode, setEditMode] = React.useState(false);
  const [rosterSettings, setRosterSettings] = React.useState({...props.league.rosterSettings})
  
  function handleRosterChange(event) {
    const {name, value} = event.target;
    let newRosterSettings = {...rosterSettings};
    newRosterSettings[name] = Number(value);
    setRosterSettings(newRosterSettings);
  }
  
  function saveRosterSettings() {
    const newLeague = {...props.league};
    newLeague.rosterSettings = {...rosterSettings};
    leagueApi.updateLeague(newLeague.id, newLeague).then(() => setEditMode(false));
  }
  
  if (editMode) {
    return <Box>
      <RosterConfig rosterSettings={rosterSettings} handleRosterChange={handleRosterChange} />
      <Button className='capsized-button' onClick={() => {
        setRosterSettings({...props.league.rosterSettings});
        setEditMode(false);
      }}>Cancel</Button>
      <Button className='capsized-button' onClick={saveRosterSettings}>Save</Button>
    </Box>
  }
  return <Box>
    {positionOrder.map(position => [position, rosterSettings![position]])
      .filter(positionInfo => positionInfo[1])
      .map(positionInfo => <Typography key={positionInfo[0]}>{positionInfo[0]} - {positionInfo[1]}</Typography>)}
    <Button className='capsized-button' onClick={() => setEditMode(true)}>Edit Division Settings</Button>
  </Box>
}

export default RosterSettings;
