import * as React from 'react';
import { League, Player, Team, WaiverFaabEvent, PlayerClaimResult, PlayerClaimResultResultEnum } from '../../../sdk/model';
import {Box, Tabs, Tab, Typography, TableContainer, Paper, Table, TableHead, TableRow, TableBody} from '@mui/material';
import { styled } from '@mui/material/styles';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import PlayerCardLink from '../../cards/playerCardLink';
import { toSalary } from '../../util/SalaryDisplay';
import WaiverFaabEventQuickView from '../leagueEvent/WaiverFaabEventQuickView';

interface waiverFaabEventFullViewProps {
  league: League,
  playerMap: Map<string | undefined, Player | undefined>,
  teamMap: Map<string | undefined, Team | undefined>,
  event: WaiverFaabEvent
}

const RESULT_TO_DESCRIPTION_MAP = new Map([
  [PlayerClaimResultResultEnum.Success, 'Success'],
  [PlayerClaimResultResultEnum.InvalidAlreadyClaimed, 'Invalid - Player already signed by team'],
  [PlayerClaimResultResultEnum.InvalidDropPlayer, 'Invalid - player designated to be dropped already dropped'],
  [PlayerClaimResultResultEnum.LosingBid, 'Failed - claimed by a team with a higher bid'],
  [PlayerClaimResultResultEnum.LosingPriority, 'Failed - claimed by a team with a higher waiver priority'],
  [PlayerClaimResultResultEnum.RosterFilled, 'Invalid - not enough room on roster'],
  [PlayerClaimResultResultEnum.SalaryCapExceeded, 'Invalid - bid would put team over the salary cap'],
])

function WaiverFaabEventFullView(props: waiverFaabEventFullViewProps) {
  const [view, setView] = React.useState('full');
  const waiverResults : PlayerClaimResult[] = props.event.waiverResults;
  const faabResults : PlayerClaimResult[] = props.event.faabResults;
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));
  return <Box>
    <Tabs value={view} onChange={(event, value) => {
      setView(value)
      }} aria-label="Auction Tabs">
      <Tab label="Detailed View" value="full" />
      <Tab label="Simplified View" value="simple" />
    </Tabs>
    {view === 'full' ? <Box>
      {waiverResults.length > 0 && <Box>
        <Typography variant="h5" component="div" color="text.secondary" sx={{ flexGrow: 1 }}>
          Waiver Claims:
        </Typography>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead sx={{fontSize : 20}}>
              <TableRow>
                <StyledTableCell>Team</StyledTableCell>
                <StyledTableCell>Claimed Player</StyledTableCell>
                <StyledTableCell>Dropped Players</StyledTableCell>
                <StyledTableCell>Result</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {waiverResults.map(claimResult => {
                const player = props.playerMap.get(claimResult.bid!.playerId)!;
                const team = props.teamMap.get(claimResult.teamId)!;
                const rowStyling = claimResult.result === PlayerClaimResultResultEnum.Success ? null : {textDecoration: 'line-through'};
                return <TableRow key={player.id}>
                  <StyledTableCell>{team.name}</StyledTableCell>
                  <StyledTableCell><Box sx={rowStyling}>
                    <PlayerCardLink player={player} leagueId={props.event.leagueId} />
                  </Box></StyledTableCell>
                  <StyledTableCell sx={rowStyling}>
                     {claimResult.bid!.dropPlayerIds!.length ? claimResult.bid!.dropPlayerIds!.map(playerId => {
                       const dropPlayer = props.playerMap.get(playerId)!;
                       return <p key={playerId}><Box sx={rowStyling}>
                         <PlayerCardLink player={dropPlayer} leagueId={props.event.leagueId} />
                       </Box></p>
                     }) : 'None'}
                  </StyledTableCell>
                  <StyledTableCell sx={claimResult.result === PlayerClaimResultResultEnum.Success ? {color: 'green'} : {color: 'red'}}>{RESULT_TO_DESCRIPTION_MAP.get(claimResult.result!)}</StyledTableCell>
                </TableRow>
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>}
      {props.event.waiverClearedPlayers.length > 0 && <Box>
        <Typography variant="h5" component="div" color="text.secondary" sx={{ flexGrow: 1 }}>
          Cleared Waivers:
        </Typography>
        {props.event.waiverClearedPlayers.map(playerId => props.playerMap.get(playerId)!).map((player, playerIndex) =>
          <React.Fragment><PlayerCardLink key={player.id} player={player} leagueId={props.event.leagueId} />{playerIndex < props.event.waiverClearedPlayers.length - 1 && ","}</React.Fragment>
        )}
      </Box>}
      {faabResults.length > 0 && <Box>
        <Typography variant="h5" component="div" color="text.secondary" sx={{ flexGrow: 1 }}>
          FAAB Claims:
        </Typography>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead sx={{fontSize : 20}}>
              <TableRow>
                <StyledTableCell>Team</StyledTableCell>
                <StyledTableCell>Claimed Player</StyledTableCell>
                <StyledTableCell>Bid</StyledTableCell>
                <StyledTableCell>Dropped Players</StyledTableCell>
                <StyledTableCell>Result</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {faabResults.map(claimResult => {
                const player = props.playerMap.get(claimResult.bid!.playerId)!;
                const team = props.teamMap.get(claimResult.teamId)!;
                const rowStyling = claimResult.result === PlayerClaimResultResultEnum.Success ? null : {textDecoration: 'line-through'};
                return <TableRow key={player.id}>
                  <StyledTableCell>{team.name}</StyledTableCell>
                  <StyledTableCell><Box sx={rowStyling}>
                    <PlayerCardLink player={player} leagueId={props.event.leagueId} />
                  </Box></StyledTableCell>
                  <StyledTableCell>{toSalary(claimResult.bid!.bidAmount)}</StyledTableCell>
                  <StyledTableCell>
                     {claimResult.bid!.dropPlayerIds!.length ? claimResult.bid!.dropPlayerIds!.map(playerId => {
                       const dropPlayer = props.playerMap.get(playerId)!;
                       return <p key={playerId}><Box sx={rowStyling}><PlayerCardLink player={dropPlayer} leagueId={props.event.leagueId} />
                       </Box></p>
                     }) : 'None'}
                  </StyledTableCell>
                  <StyledTableCell sx={claimResult.result === PlayerClaimResultResultEnum.Success ? {color: 'green'} : {color: 'red'}}>{RESULT_TO_DESCRIPTION_MAP.get(claimResult.result!)}</StyledTableCell>
                </TableRow>
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>}
    </Box> : <WaiverFaabEventQuickView event={props.event} playerMap={props.playerMap} teamMap={props.teamMap} />}
  </Box>
}

export default WaiverFaabEventFullView;